


@-webkit-keyframes jump {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,50%,0);transform: translate3d(0,50%,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}
@keyframes jump {
  0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%  {-webkit-transform: translate3d(0,50%,0);transform: translate3d(0,50%,0);}
  100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
}

@-webkit-keyframes rotated {
  0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@keyframes rotated {
  0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

@-webkit-keyframes rotatedHalf {
  0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  50% {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
  100%   {-webkit-transform: rotate(0);transform: rotate(0);}
}
@keyframes rotatedHalf {
  0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  50% {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
  100%   {-webkit-transform: rotate(0);transform: rotate(0);}
}

@-webkit-keyframes rotatedHalfTwo {
  0% {-webkit-transform: rotate(-90deg);transform: rotate(-90deg);}
  100%   {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
}
@keyframes rotatedHalfTwo {
  0% {-webkit-transform: rotate(-90deg);transform: rotate(-90deg);}
  100%   {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
}


@-webkit-keyframes scale-upOne {
  0%   {-webkit-transform: scale(1);transform: scale(1);}
  100% {-webkit-transform: scale(0.2);transform: scale(0.2);}
}
@keyframes scale-upOne {
  0%   {-webkit-transform: scale(1);transform: scale(1);}
  100% {-webkit-transform: scale(0.2);transform: scale(0.2);}
}

@-webkit-keyframes scale-right {
  0%  {-webkit-transform: translateX(-50%);transform: translateX(-50%);}
  50% {-webkit-transform: translateX(50%);transform: translateX(50%);}
  100% {-webkit-transform: translateX(-50%);transform: translateX(-50%);}
}
@keyframes scale-right {
  0%  {-webkit-transform: translateX(-50%);transform: translateX(-50%);}
  50% {-webkit-transform: translateX(50%);transform: translateX(50%);}
  100% {-webkit-transform: translateX(-50%);transform: translateX(-50%);}
}

@-webkit-keyframes fade-in {
  0%   {opacity: 0.7;}
  40%  {opacity: 1;}
  100% {opacity: 0.7;}
}
@keyframes fade-in {
  0%   {opacity: 0.7;}
  40%  {opacity: 1;}
  100% {opacity: 0.7;}
}


@keyframes hvr-ripple-out {
  0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  100% {
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
  }
}
@keyframes hvr-ripple-out-two {
  0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  100% {
    top: -18px;
    right: -18px;
    bottom: -18px;
    left: -18px;
    opacity: 0;
  }
}

@-webkit-keyframes scale-up-one {
  0%   {-webkit-transform: scale(1);transform: scale(1);}
  40%  {-webkit-transform: scale(0.5);transform: scale(0.5);}
  100% {-webkit-transform: scale(1);transform: scale(1);}
}
@keyframes scale-up-one {
  0%   {-webkit-transform: scale(1);transform: scale(1);}
  40%  {-webkit-transform: scale(0.5);transform: scale(0.5);}
  100% {-webkit-transform: scale(1);transform: scale(1);}
}
@-webkit-keyframes scale-up-two {
  0%   {-webkit-transform: scale(0.5);transform: scale(0.5);}
  40%  {-webkit-transform: scale(0.8);transform: scale(0.8);}
  100% {-webkit-transform: scale(0.5);transform: scale(0.5);}
}
@keyframes scale-up-two {
  0%   {-webkit-transform: scale(0.5);transform: scale(0.5);}
  40%  {-webkit-transform: scale(0.8);transform: scale(0.8);}
  100% {-webkit-transform: scale(0.5);transform: scale(0.5);}
}
@-webkit-keyframes scale-up-three {
  0%   {-webkit-transform: scale(0.7);transform: scale(0.7);}
  40%  {-webkit-transform: scale(0.4);transform: scale(0.4);}
  100% {-webkit-transform: scale(0.7);transform: scale(0.7);}
}
@keyframes scale-up-three {
  0%   {-webkit-transform: scale(0.7);transform: scale(0.7);}
  40%  {-webkit-transform: scale(0.4);transform: scale(0.4);}
  100% {-webkit-transform: scale(0.7);transform: scale(0.7);}
}


@keyframes animationFramesOne{
  0% {
    transform:  translate(0px,0px)  rotate(0deg) ;
    -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
    -moz-transform:  translate(0px,0px)  rotate(0deg) ;
    -ms-transform:  translate(0px,0px)  rotate(0deg) ;
    -o-transform:  translate(0px,0px)  rotate(0deg) ;
}
  20% {
    transform:  translate(73px,-1px)  rotate(36deg) ;
    -webkit-transform:  translate(73px,-1px)  rotate(36deg) ;
    -moz-transform:  translate(73px,-1px)  rotate(36deg) ;
    -ms-transform:  translate(73px,-1px)  rotate(36deg) ;
    -o-transform:  translate(73px,-1px)  rotate(36deg) ;
}
  40% {
    transform:  translate(141px,-20px)  rotate(72deg) ;
    -webkit-transform:  translate(141px,-20px)  rotate(72deg) ;
    -moz-transform:  translate(141px,-20px)  rotate(72deg) ;
    -ms-transform:  translate(141px,-20px)  rotate(72deg) ;
    -o-transform:  translate(141px,-20px)  rotate(72deg) ;
}
  60% {
    transform:  translate(83px,-60px)  rotate(108deg) ;
    -webkit-transform:  translate(83px,-60px)  rotate(108deg) ;
    -moz-transform:  translate(83px,-60px)  rotate(108deg) ;
    -ms-transform:  translate(83px,-60px)  rotate(108deg) ;
    -o-transform:  translate(83px,-60px)  rotate(108deg) ;
}
  80% {
    transform:  translate(-40px,72px)  rotate(144deg) ;
    -webkit-transform:  translate(-40px,72px)  rotate(144deg) ;
    -moz-transform:  translate(-40px,72px)  rotate(144deg) ;
    -ms-transform:  translate(-40px,72px)  rotate(144deg) ;
    -o-transform:  translate(-40px,72px)  rotate(144deg) ;
}
  100% {
    transform:  translate(0px,0px)  rotate(0deg) ;
    -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
    -moz-transform:  translate(0px,0px)  rotate(0deg) ;
    -ms-transform:  translate(0px,0px)  rotate(0deg) ;
    -o-transform:  translate(0px,0px)  rotate(0deg) ;
}
}


@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
  }
  20% {
    -webkit-transform:  translate(73px,-1px)  rotate(36deg) ;
  }
  40% {
    -webkit-transform:  translate(141px,72px)  rotate(72deg) ;
  }
  60% {
    -webkit-transform:  translate(83px,122px)  rotate(108deg) ;
  }
  80% {
    -webkit-transform:  translate(-40px,72px)  rotate(144deg) ;
  }
  100% {
    -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
  }
}

@keyframes animationFramesTwo{
  0% {
    transform:  translate(0px,0px)  rotate(0deg) scale(1) ;
  }
  20% {
    transform:  translate(73px,-1px)  rotate(36deg) scale(0.9);
  }
  40% {
    transform:  translate(141px,72px)  rotate(72deg) scale(1);
  }
  60% {
    transform:  translate(83px,122px)  rotate(108deg) scale(1.2);
  }
  80% {
    transform:  translate(-40px,72px)  rotate(144deg) scale(1.1);
  }
  100% {
    transform:  translate(0px,0px)  rotate(0deg) scale(1);
  }
}


@-webkit-keyframes animationFramesTwo {
  0% {
    -webkit-transform:  translate(0px,0px)  rotate(0deg) scale(1);
  }
  20% {
    -webkit-transform:  translate(73px,-1px)  rotate(36deg) scale(0.9);
  }
  40% {
    -webkit-transform:  translate(141px,72px)  rotate(72deg) scale(1);
  }
  60% {
    -webkit-transform:  translate(83px,122px)  rotate(108deg) scale(1.2);
  }
  80% {
    -webkit-transform:  translate(-40px,72px)  rotate(144deg) scale(1.1);
  }
  100% {
    -webkit-transform:  translate(0px,0px)  rotate(0deg) scale(1);
  }
}


@keyframes animationFramesThree{
  0% {
    transform:  translate(165px,-30px)  ;
    -webkit-transform:  translate(165px,-30px)  ;
    -moz-transform:  translate(165px,-30px)  ;
    -ms-transform:  translate(165px,-30px)  ;
    -o-transform:  translate(165px,-30px)  ;
}
  100% {
    transform:  translate(-60px,80px)  ;
    -webkit-transform:  translate(-60px,80px)  ;
    -moz-transform:  translate(-60px,80px)  ;
    -ms-transform:  translate(-60px,80px)  ;
    -o-transform:  translate(-60px,80px)  ;
}
}


@-webkit-keyframes animationFramesThree{
  0% {
    transform:  translate(165px,-30px)  ;
    -webkit-transform:  translate(165px,-30px)  ;
    -moz-transform:  translate(165px,-30px)  ;
    -ms-transform:  translate(165px,-30px)  ;
    -o-transform:  translate(165px,-30px)  ;
}
  100% {
    transform:  translate(-60px,80px)  ;
    -webkit-transform:  translate(-60px,80px)  ;
    -moz-transform:  translate(-60px,80px)  ;
    -ms-transform:  translate(-60px,80px)  ;
    -o-transform:  translate(-60px,80px)  ;
}
}


@keyframes animationFramesFour{
  0% {
    transform:  translate(-0px,60px)  rotate(0deg) ;
    -webkit-transform:  translate(-0px,60px)  rotate(0deg) ;
    -moz-transform:  translate(-0px,60px)  rotate(0deg) ;
    -ms-transform:  translate(-0px,60px)  rotate(0deg) ;
    -o-transform:  translate(-0px,60px)  rotate(0deg) ;
}
  100% {
    transform:  translate(-100px,-100px)  rotate(180deg) ;
    -webkit-transform:  translate(-100px,-100px)  rotate(180deg) ;
    -moz-transform:  translate(-100px,-100px)  rotate(180deg) ;
    -ms-transform:  translate(-100px,-100px)  rotate(180deg) ;
    -o-transform:  translate(-100px,-100px)  rotate(180deg) ;
}
}


@-webkit-keyframes animationFramesFour{
  0% {
    transform:  translate(-0px,60px)  rotate(0deg) ;
    -webkit-transform:  translate(-0px,60px)  rotate(0deg) ;
    -moz-transform:  translate(-0px,60px)  rotate(0deg) ;
    -ms-transform:  translate(-0px,60px)  rotate(0deg) ;
    -o-transform:  translate(-0px,60px)  rotate(0deg) ;
}
  100% {
    transform:  translate(-100px,-100px)  rotate(180deg) ;
    -webkit-transform:  translate(-100px,-100px)  rotate(180deg) ;
    -moz-transform:  translate(-100px,-100px)  rotate(180deg) ;
    -ms-transform:  translate(-100px,-100px)  rotate(180deg) ;
    -o-transform:  translate(-100px,-100px)  rotate(180deg) ;
}
}


@keyframes animationFramesFive{
  0% {
    transform:  translate(0,0)  rotate(0deg) ;
    -webkit-transform:  translate(0,0)  rotate(0deg) ;
    -moz-transform:  translate(0,0)  rotate(0deg) ;
    -ms-transform:  translate(0,0)  rotate(0deg) ;
    -o-transform:  translate(0,0)  rotate(0deg) ;
}
  21% {
    transform:  translate(4px,-20px)  rotate(38deg) ;
    -webkit-transform:  translate(4px,-20px)  rotate(38deg) ;
    -moz-transform:  translate(4px,-20px)  rotate(38deg) ;
    -ms-transform:  translate(4px,-20px)  rotate(38deg) ;
    -o-transform:  translate(4px,-20px)  rotate(38deg) ;
}
  41% {
    transform:  translate(-50px,-60px)  rotate(74deg) ;
    -webkit-transform:  translate(-50px,-60px)  rotate(74deg) ;
    -moz-transform:  translate(-50px,-60px)  rotate(74deg) ;
    -ms-transform:  translate(-50px,-60px)  rotate(74deg) ;
    -o-transform:  translate(-50px,-60px)  rotate(74deg) ;
}
  60% {
    transform:  translate(-20px,-30px)  rotate(108deg) ;
    -webkit-transform:  translate(-20px,-30px)  rotate(108deg) ;
    -moz-transform:  translate(-20px,-30px)  rotate(108deg) ;
    -ms-transform:  translate(-20px,-30px)  rotate(108deg) ;
    -o-transform:  translate(-20px,-30px)  rotate(108deg) ;
}
  80% {
    transform:  translate(-195px,-49px)  rotate(144deg) ;
    -webkit-transform:  translate(-195px,-49px)  rotate(144deg) ;
    -moz-transform:  translate(-195px,-49px)  rotate(144deg) ;
    -ms-transform:  translate(-195px,-49px)  rotate(144deg) ;
    -o-transform:  translate(-195px,-49px)  rotate(144deg) ;
}
  100% {
    transform:  translate(-1px,0px)  rotate(180deg) ;
    -webkit-transform:  translate(-1px,0px)  rotate(180deg) ;
    -moz-transform:  translate(-1px,0px)  rotate(180deg) ;
    -ms-transform:  translate(-1px,0px)  rotate(180deg) ;
    -o-transform:  translate(-1px,0px)  rotate(180deg) ;
}
}


@-webkit-keyframes animationFramesFive{
  0% {
    transform:  translate(0,0)  rotate(0deg) ;
    -webkit-transform:  translate(0,0)  rotate(0deg) ;
    -moz-transform:  translate(0,0)  rotate(0deg) ;
    -ms-transform:  translate(0,0)  rotate(0deg) ;
    -o-transform:  translate(0,0)  rotate(0deg) ;
}
  21% {
    transform:  translate(4px,-20px)  rotate(38deg) ;
    -webkit-transform:  translate(4px,-20px)  rotate(38deg) ;
    -moz-transform:  translate(4px,-20px)  rotate(38deg) ;
    -ms-transform:  translate(4px,-20px)  rotate(38deg) ;
    -o-transform:  translate(4px,-20px)  rotate(38deg) ;
}
  41% {
    transform:  translate(-50px,-60px)  rotate(74deg) ;
    -webkit-transform:  translate(-50px,-60px)  rotate(74deg) ;
    -moz-transform:  translate(-50px,-60px)  rotate(74deg) ;
    -ms-transform:  translate(-50px,-60px)  rotate(74deg) ;
    -o-transform:  translate(-50px,-60px)  rotate(74deg) ;
}
  60% {
    transform:  translate(-20px,-30px)  rotate(108deg) ;
    -webkit-transform:  translate(-20px,-30px)  rotate(108deg) ;
    -moz-transform:  translate(-20px,-30px)  rotate(108deg) ;
    -ms-transform:  translate(-20px,-30px)  rotate(108deg) ;
    -o-transform:  translate(-20px,-30px)  rotate(108deg) ;
}
  80% {
    transform:  translate(-195px,-49px)  rotate(144deg) ;
    -webkit-transform:  translate(-195px,-49px)  rotate(144deg) ;
    -moz-transform:  translate(-195px,-49px)  rotate(144deg) ;
    -ms-transform:  translate(-195px,-49px)  rotate(144deg) ;
    -o-transform:  translate(-195px,-49px)  rotate(144deg) ;
}
  100% {
    transform:  translate(-1px,0px)  rotate(180deg) ;
    -webkit-transform:  translate(-1px,0px)  rotate(180deg) ;
    -moz-transform:  translate(-1px,0px)  rotate(180deg) ;
    -ms-transform:  translate(-1px,0px)  rotate(180deg) ;
    -o-transform:  translate(-1px,0px)  rotate(180deg) ;
}
}


